/* Error: Top-level selectors may not contain the parent selector "&".
 *     ,
 * 303 |     .navbar &.dropdown:hover {
 *     |             ^
 *     '
 *   mixins.scss 303:13       page-home-menu-hover--md()
 *   media-queries.scss 37:5  root stylesheet */

body::before {
  font-family: "Source Code Pro", "SF Mono", Monaco, Inconsolata, "Fira Mono",
      "Droid Sans Mono", monospace, monospace;
  white-space: pre;
  display: block;
  padding: 1em;
  margin-bottom: 1em;
  border-bottom: 2px solid black;
  content: 'Error: Top-level selectors may not contain the parent selector "&".\a     \2577 \a 303 \2502      .navbar &.dropdown:hover {\a     \2502              ^\a     \2575 \a   mixins.scss 303:13       page-home-menu-hover--md()\a   media-queries.scss 37:5  root stylesheet';
}
